import { Mesh, MeshBasicMaterial, Vector3 } from 'three'
import FullscreenPlaneGeometry from '@/webgl/geometries/FullscreenPlaneGeometry'

export default class DebugPlane extends Mesh {
  size!: Vector3

  constructor () {
    super(
      new FullscreenPlaneGeometry(),
      new MeshBasicMaterial({
        wireframe: true,
        color: 'red',
      }),
    )

    this.setSize(1, 1)
  }

  setSize (width: number, height: number) {
    const geometry = this.geometry as FullscreenPlaneGeometry
    geometry.setSize(width, height)
    geometry.computeBoundingBox()
    
    if (geometry.boundingBox) {
      this.size = geometry.boundingBox.getSize(new Vector3())
    }
  }

  dispose () {
    const geometry = this.geometry as FullscreenPlaneGeometry
    const material = this.material as MeshBasicMaterial
    
    geometry.dispose()
    material.dispose()
  }
}