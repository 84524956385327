


































































import { Component, Vue } from 'vue-property-decorator'
import { system, graphics, mobile, agent, dpr } from '@/vue/modules/device'
import { TIER, M_TIER, getDPR, getFPS, getPP, getWT, convert } from '@/vue/modules/gpu'

@Component
export default class Specs extends Vue {
  get system () {
    return system
  }

  get graphics () {
    return graphics
  }

  get mobile () {
    return mobile
  }

  get agent () {
    return agent
  }

  get dpr () {
    return dpr
  }

  get getPP () {
    return getPP()
  }

  get getDPR () {
    return getDPR()
  }

  get getFPS () {
    return getFPS()
  }

  get getWT () {
    const wt = getWT()
    return wt === 0 ? false : wt
  }

  get tier () {
    return `${mobile ? convert(M_TIER) : convert(TIER)} [${mobile ? M_TIER : TIER}]`
  }

  get size () {
    const { 
      innerWidth: width,
      innerHeight: height 
    } = window
    return `${width} x ${height}`
  }
}
