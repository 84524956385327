




import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Stats extends Vue {
  mounted () {
    this.$el.appendChild(this.$stats.dom)
  }

  destroyed () {
    this.$el.removeChild(this.$stats.dom)
  }
}
